import { BookingSlot, Offer } from '../models';
import { makeAutoObservable } from 'mobx';
import agent from '../api/agent';
import { BookingSlotLockRequest, BookingSlotLockResponse, BookingSlotUnLockRequest } from '../models/bookingSlot';
export default class WaitlistStore {
    isLoading : boolean = true;
    currentOffer : Offer | undefined = undefined;
    selectedOfferItem: string = '';
    availableSlots: BookingSlot[] | undefined = undefined;    
    lockSlotResponse: BookingSlotLockResponse | undefined = undefined;

    constructor() {
        makeAutoObservable(this);
    }

    loadAvailableSlotsForWaitlistedCustomer = async (customerId: string) => {
        try{
            const slots = await agent.WaitList.getAvailableSlotsForWaitlist(customerId);
            this.setAvailableSlots(slots);
        }
        catch (error)
        {
            console.error(error); 
        }
    }


    setAvailableSlots = (slots: BookingSlot[]) => {
        this.availableSlots = [...slots];
    }    

    loadOffer = async (offerId: string) => {
        try {
            this.setIsLoading(true);
            let offer = await agent.WaitList.getOffers(offerId);
            this.setCurrentOffer(offer);
            this.setIsLoading(false);
        }
        catch (error) {
            console.error(error);
        }
    }

    rejectOffer = async (offerId: string) => {
        try {
            this.setIsLoading(true);
            await agent.WaitList.rejectOffer(offerId);
            this.setIsLoading(false);
        }
        catch (error) {
            console.error(error);
        }
    }

    AcceptOffer = async (offerId: string, offerItemId: string) => {
        try {
            this.setIsLoading(true);
            await agent.WaitList.acceptOffer(offerId, offerItemId);
            this.setIsLoading(false);
        }
        catch (error) {
            console.error(error);
        }
    }


    setCurrentOffer = (offer: Offer) => {
        this.currentOffer = offer;
        console.log("setCurrentOffer",JSON.stringify(offer))
    }

    setSelectedOfferItem = (offerItem: string) => {
        this.selectedOfferItem = offerItem;
        console.log("setSelectedOffer", offerItem)
    }
    
    setIsLoading = (loading: boolean) => {
        this.isLoading = loading;
    }

    lockSlot = async (request: BookingSlotLockRequest) => {
        let response = await agent.WaitList.lockSlot(request);
        this.setLockSlotResponse(response);
    }

    unlockSlot = async (request: BookingSlotUnLockRequest) => {
        await agent.WaitList.unlockSlot(request);        
    }

    setLockSlotResponse = (response: BookingSlotLockResponse) => {
        this.lockSlotResponse = response;
    }
}
