import { Survey, SurveyFor } from '../models';
import { makeAutoObservable } from 'mobx';
import agent from '../api/agent';

export default class SurveyStore {
    cancelSurveyOptions: Survey | undefined = undefined;
    nobookingSurveyOptions: Survey | undefined = undefined;
    isLoading : boolean = true;
    constructor() {
        makeAutoObservable(this);
    }

    loadCancelSurveyOptions = async () => {
        try {
            this.setIsLoading(true);
                let survey = await agent.SurveyMessage.getOptions(SurveyFor.CancelBooking);
                this.setCancelSurveyOptions(survey);
                this.setIsLoading(false);
        }
        catch (error) {
            console.error(error);
        }
    }

    loadNobookingSurveyOptions = async () => {
        try {
            let survey = await agent.SurveyMessage.getOptions(SurveyFor.NoBooking);
            this.setNobookingSurveyOptions(survey);
        }
        catch (error) {
            console.error(error);
        }
    }

    setCancelSurveyOptions = (survey: Survey) => {
        this.cancelSurveyOptions = survey;
        console.log("setCancelSurveyOptions",JSON.stringify(this.cancelSurveyOptions))
    }

    setNobookingSurveyOptions = (survey: Survey) => {
        this.nobookingSurveyOptions = survey;
        console.log("setNobookingSurveyOptions",JSON.stringify(survey))
    }

    setIsLoading = (loading: boolean) => {
        this.isLoading = loading;
    }

}