import { Booking, BookingSlot, CancelBookingInput, SurveyFor, SurveyOnCancellation } from '../models';
import { makeAutoObservable } from 'mobx';
import agent from '../api/agent';
import { BookingResult, SkipBookingInput } from '../models/booking';
import { element } from 'prop-types';

export default class BookingStore {
    booking: Booking | undefined = undefined;
    availableSlots: BookingSlot[] | undefined = undefined;
    timeSelected: string = '';
    currentBooking: Booking | undefined = undefined;
    bookingNumber: string = '';
    isLoading = true;
    customerId: string = '';
    bookingResult: BookingResult | undefined = undefined;

    constructor() {
        makeAutoObservable(this);
    }

    bookTimeSlot = async () => {
        try {
            if (this.booking) {
                const bookingResult = await agent.Bookings.createBooking(this.booking);
                this.setBookingResult(bookingResult);
                return bookingResult;
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    rescheduleBooking = async (isRescheduledFromWaitlistOffer?: boolean) => {
        try {
            if (this.booking) {

                const bookingResult = await agent.Bookings.updateBooking({
                    customerId: this.booking.customerId,
                    bookingId: this.bookingNumber,
                    newRepairDate: this.booking.repairDate,
                    newDropOffTime: this.booking.dropOffTime,
                    reason: this.booking.comments,
                    rescheduledThroughWaitlistOffer: isRescheduledFromWaitlistOffer ? true: false
                });

                this.setBookingResult(bookingResult);
            }
        }
        catch (error) {
            console.error(error);
        }
    }


    setBookingResult = (bookingResult?: BookingResult) => {
        this.bookingResult = bookingResult;
    }

    loadAvailableSlots = async (facilityId: string, repairType: string) => {
        
        //don't intialise booking if customer has navigated back to reselect his booking
        if(!this.booking)
        {
            const booking = {
                customerId: '',
                repairType: repairType,
                repairDate: '',
                dropOffTime: '',
                facilityId: facilityId,
                comments: '',
            } as Booking;

            
            this.setBooking(booking);
        }else{                
               //if booking date/time has been previously selected (but hasn't yet been confirmed) and then repair type has changed, we need to update booking repair type as well
               this.setRepairType(repairType);
        }

        try {
            const slots = await agent.Bookings.getAllAvailableSlots(facilityId, repairType);
            this.setAvailableSlots(slots);
        }
        catch (error) {
            console.error(error);
        }
    }

    cancelBooking = async (bookingId : string, customerId: string, surveyId: string, surveyLabel : string, comments: string ) =>{
        let cancelBookingInput = <CancelBookingInput>({
            bookingId: bookingId,
            customerId: customerId,
            surveyOnCancellation: <SurveyOnCancellation>({
                surveyId: surveyId,
                selectedSurveyQuestions: [surveyLabel],
                other: comments
            })
        })

        try{
            await agent.Bookings.cancelBooking(cancelBookingInput);

            this.setCurrentBooking(undefined); //clear booking since it's been cancelled
            this.setBooking(undefined); //clear booking since it's been cancelled
            this.setBookingResult(undefined); //clear any previous booking result of the booking that was done, since it's been cancelled
        }
        catch (error) {
            console.error(error);
        }
        
    }

    skipBooking = async (customerId: string, surveyId: string, surveyLabel : string, comments: string ) =>{
        let skipBookingInput = <SkipBookingInput>({
            customerId: customerId,
                surveyId: surveyId,
                selectedSurveyQuestions: [surveyLabel],
                other: comments
        })
        await agent.Bookings.skipBooking(skipBookingInput);
    }

    loadCurrentBooking = async () => {
        try {
            const booking = await agent.Bookings.getBooking(this.bookingNumber);
            this.setCurrentBooking(booking);
            this.setBooking(booking);
        }
        catch (error) {
            console.error(error);

            this.setIsLoading(false);
        }
    }

    loadCurrentBookingOfCustomer = async () => {
        try {
            const booking = await agent.Customers.getCurrentBooking(this.customerId);
            this.setCurrentBooking(booking);
            this.setBooking(booking);
            this.setBookingNumber(booking.bookingId);
        }
        catch (error) {
            console.error(error);

            this.setIsLoading(false);
        }
    }

    setCurrentBooking = (booking?: Booking) => {
        this.currentBooking = booking;
        console.log("setCurrentBooking", JSON.stringify(booking));
    }

    setIsLoading = (loading: boolean) => {
        this.isLoading = loading;
    }

    setBooking = (booking?: Booking) => {       
        this.booking = booking;
        console.log("setBooking", JSON.stringify(booking));
    }

    setBookingNumber = (bookingNumber: string) => {
        this.bookingNumber = bookingNumber;
    }

    setCustomerId = (customerId: string) => {
        this.customerId = customerId;
    }

    setAvailableSlots = (slots: BookingSlot[]) => {
        this.availableSlots = [...slots];
       
        //if booking date/time has been previously selected (but hasn't yet been confirmed) and user re-visits booking selection screen due to:
        // scenario 1: either repair type had changed and app navigated the user back to ready to book screen and he moved forward to this screen, or 
        // scenario 2: user had moved from this screen to booking selected screen and then navigated back to this screen 
        // when slots are reloaded/refershed, then the previously selected booking might become invalid as it's no longer available with changed repair type (highly probable) 
        // or has been booked by another user of same repair type while user was navigating around (low probability but still possible)
        // Either way the rule of thumb should be:
        // when slots are reloaded, if previously selected booking slot still exists (app will select it and bring it into focus), then enable "choose this time (next)" action button to confirm the selected booking slot   
        // else disable "choose this time (next)" action button and force the user to select a slot based on new availability, since previously selected booking is no longer valid    

        //check if previsouly selected/current booking slot is still valid and exists in the fetched available slots
        //if it doesn't exist, it means that previsouly selected/current booking slot is invalid and user should be forced to re-select a slot
        if(this.booking)
        {
            let doesPreviouslySelectedBookingExistInAvailableSlots = this.availableSlots.some(s => (s.repairDate == this.booking?.repairDate && s.dropOffTimes.includes(this.booking.dropOffTime)));    
            //if previous booking slot doesn't exist, then clear previous selected time as it's invalid and disable "choose this time (next)" action button
            if(!doesPreviouslySelectedBookingExistInAvailableSlots)
            {
                this.setTimeSelected(''); //this will disable "choose this time (next)" action button
            }
        }
    }

    setTimeSelected = (time: string) => {
        this.timeSelected = time;
    }

    setRepairType = (repairType:string) => {
        if(this.booking)
            this.booking.repairType = repairType;
    }
}