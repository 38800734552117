import { Offer } from '../models';
import { makeAutoObservable } from 'mobx';
import agent from '../api/agent';
export default class AppStore {
    isLoading : boolean = true;

    constructor() {
        makeAutoObservable(this);
    }
    
    setIsLoading = (loading: boolean) => {
        this.isLoading = loading;
    }
}
