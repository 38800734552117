export default interface Survey {
	surveyId: string;
	options: string[]
}

export enum SurveyFor {
	NoBooking = "NoBooking",
	CancelBooking = "CancelBooking"
}

export interface SurveyOnCancellation {
	surveyId: string,
	selectedSurveyQuestions: string[],
	other: string
}