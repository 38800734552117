import React from 'react';
import { Store, StoreContext } from './src/stores';

const initialTheme = 'light';
const ThemeContext = React.createContext(initialTheme);
document.body.classList.add('euiBody--headerIsFixed--double');

export const wrapRootElement = ({ element }) => {
    return (
        <StoreContext.Provider value={Store}>
            <ThemeContext.Provider value={initialTheme}>
                {element}
            </ThemeContext.Provider>
        </StoreContext.Provider>
    );
};