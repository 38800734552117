import * as Yup from "yup"
import RepairType from "./repairType"

export const RepairTypes = [
  new RepairType(
    0,
    "PDR",
    "Paintless Dent Removal",
    "Your car has light hail damage. The full repair will be performed using the Paintless Dent Repair method. PDR is the most time efficient and exacting hail repair available for your car. This time frame is an approximation. Your repair can be significantly shorter if we are able to use two PDR technicians.",
    1,
    "1 week to completion"
    // "PDR (Paintless Dent Repair) – 1 Week"
  ),
  new RepairType(
    1,
    "PTP",
    "Push to Paint",
    "Your car has a medium severity of hail damage. Your car requires at least a single full panel to be replaced and/or painted. The majority of the repair is done using the Paintless Dent Repair method. Once the PDR is completed your vehicle will be moved to our paint facility where that panel will be replaced and/or painted. *Please note as two repair processes are required this time frame is an approximation.",
    2,
    "PDR + Light Paint – 2 Weeks"
  ),
  new RepairType(
    2,
    "CON",
    "Conventional Repair",
    "Your car has a heavy severity of hail damage. Your car requires two or more full panels to be replaced and/or painted. A portion of the repair uses the Paintless Dent Repair method. Once the PDR is completed your vehicle will be moved to our paint facility where the panels will be replaced and/or painted. *Please note as two repair processes are required this time frame is an approximation.",
    4,
    "PDR + Heavy Paint – 4 Weeks"
  ),
  new RepairType(
    3,
    "TL",
    "Total Loss",
    "An Insurance Assessor will come to talk to you about your vehicle shortly.",
    0,
    "Total Loss"
  ),
  new RepairType(
    4,
    "PSR",
    "Paint Shop Repair",
    "An Insurance Assessor will come to talk to you about your vehicle shortly.",
    0,
    "Paint Shop Repair"
  ),
]

export const ValidationSchema = Yup.object().shape({
  firstName: Yup.string().nullable().required("First Name is required."),
  lastName: Yup.string().nullable().required("Last Name is required."),
  mobile: Yup.string().nullable().required("Mobile is required."),
  email: Yup.string()
    .nullable()
    .required("Email is required.")
    .email("Must be a valid email address."),
  confirmEmail: Yup.string()
    .nullable()
    .required("Confirm Email is required.")
    .oneOf([Yup.ref("email"), null], "Emails don't match."),
  //vehicleRego: Yup.string().nullable().required("Registration is required."),
  //vehicleMake: Yup.string().nullable().required("Vehicle make is required."),
  //vehicleModel: Yup.string().nullable().required("Vehicle model is required."),
})

export const ProcessStates = [
  {
    Id: 1,
    Name: "DetailsConfirmed",
    RelativeUrl: "assessment",
  },
  {
    Id: 2,
    Name: "ReadyToBook",
    RelativeUrl: "ready-to-book",
  },
  {
    Id: 3,
    Name: "BookingConfirmed",
    RelativeUrl: "booking-confirmed",
  },
]

export const CancelBookingValidation = Yup.object().shape({
  cancelReason: Yup.string().nullable().required("Please select reason"),
})

export const CompanyContactNumber = '1300 395 395';