import axios, { AxiosResponse } from 'axios';
import { BookingSlot, Customer, Facility, Offer, Survey, SurveyFor } from '../models';
import Booking, { BookingResult, CancelBookingInput, SkipBookingInput } from '../models/booking';
import { BookingSlotLockRequest, BookingSlotLockResponse, BookingSlotUnLockRequest } from '../models/bookingSlot';

axios.defaults.baseURL = process.env.GATSBY_API_BASE_URL;

axios.interceptors.request.use(config => {
    const token = localStorage.getItem(process.env.GATSBY_LSK_HAILCX_API_TOKEN ?? 'hailcx-api-token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

axios.interceptors.response.use(async response => {
    try {
        //await sleep(2000);
        return response;
    } catch (error) {
        console.log(error);
        return await Promise.reject(error);
    }
});

const responseBody = <T> (response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T> (url: string) => axios.get<T>(url).then(responseBody),
    post: <T> (url: string, body?: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T> (url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T> (url: string) => axios.delete<T>(url).then(responseBody),
}

const tokenEndpoint = process.env.GATSBY_API_GET_TOKEN as string;

const Identity = {
    getToken: (encryptedQuoteNumber: string) => requests.post<string>(tokenEndpoint, { encryptedURLEncodedData: encryptedQuoteNumber, isUrlEncoded: false }),
}

const SurveyMessage = {
    getOptions: (surveyFor : SurveyFor)=> requests.get<Survey>(`/survey/?surveyFor=${surveyFor}`),
}

const Customers = {
    getCustomer: (quoteNumber: string) => requests.post<Customer>(`/customer/${quoteNumber}`),
    details: (encryptedQuoteNumber: string) => requests.post<Customer>(`/customer/getCustomerByEncryptedQuoteNumber`, { encryptedURLEncodedData: encryptedQuoteNumber, isUrlEncoded: false }),
    update: (customerId: string, data: {}) => requests.put<Customer>(`/customer/${customerId}`, data),
    setProcessState: (quoteNumber: string, state: string) => requests.post(`/customer/setBookingProcessFlowState`, { customerQuoteNumber: quoteNumber, processState: state }),
    addCustomerToWaitlist: (customerId: string) => requests.post(`/customer/${customerId}/addToWaitlist`),
    removeCustomerFromWaitlist: (customerId: string) => requests.post(`/customer/${customerId}/removeFromWaitlist`),
    isCustomerWaitlisted: (customerId: string) => requests.get<Boolean>(`/customer/${customerId}/isWaitlisted`),
    getCurrentBooking: (customerId: string) => requests.get<Booking>(`/customer/${customerId}/currentBooking`),
}

const Facilities = {
    getFacility: (facilityId: string) => requests.get<Facility>(`/facility/${facilityId}`),
}

const WaitList = {
    getAvailableSlotsForWaitlist: (customerId: string) => requests.get<BookingSlot[]>(`/waitlist/availableSlotsForWaitlist?customerId=${customerId}`),
    lockSlot: (data: BookingSlotLockRequest) => requests.post<BookingSlotLockResponse>('/waitlist/lockSlot', data),
    unlockSlot: (data: BookingSlotUnLockRequest) => requests.post('/waitlist/unlockSlot', data),
}

const Bookings = {
    getAllAvailableSlots: (facilityId: string, repairType: string) => requests.get<BookingSlot[]>(`/booking/allAvailableSlots?facilityId=${facilityId}&repairType=${repairType}`),
    cancelBooking: (input: CancelBookingInput) => requests.post<CancelBookingInput>('/booking/cancel', input),
    skipBooking: (input: SkipBookingInput) => requests.post<SkipBookingInput>('/booking/skip', input),
    createBooking: (booking: Booking) => requests.post<BookingResult>('/booking', booking),
    getBooking: (id: string) => requests.get<Booking>(`/booking/${id}`),
    updateBooking: (data: {}) => requests.post<BookingResult>('/booking/reschedule', data),
}

const agent = {
    Identity,
    Customers,
    Facilities,
    Bookings,
    SurveyMessage,
    WaitList
}

export default agent;