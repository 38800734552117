import { Facility } from '../models';
import { makeAutoObservable } from 'mobx';
import agent from '../api/agent';
import { CompanyContactNumber } from '../entities/constants';

export default class FacilityStore {
    facility: Facility | undefined = undefined;

    constructor() {
        makeAutoObservable(this);
    }

    loadFacilityDetails = async (facilityId: string | undefined) => {
        try {
            if(facilityId != undefined)
            {
                const facility = await agent.Facilities.getFacility(facilityId);
                this.setFacility(facility);
            }            
        }
        catch (error) {
            console.error(error);
        }
    }

    setFacility = (facility: Facility) => {
        this.facility = facility;
        this.facility.contactDetails = this.facility.contactDetails !== '' ? facility?.contactDetails as string: CompanyContactNumber;
        console.log("setFacility",JSON.stringify(facility))
    }
}