import { createContext, useContext } from "react";
import AppStore from "./appStore";
import BookingStore from './bookingStore';
import CustomerStore from './customerStore';
import FacilityStore from './facilityStore';
import SurveyStore from './surveyStore';
import WaitlistStore from "./waitlistStore";

interface Store {
    customerStore: CustomerStore;
    facilityStore: FacilityStore;
    bookingStore: BookingStore;
    surveyStore: SurveyStore;
    waitlistStore: WaitlistStore;
    appStore: AppStore;
}

export const Store: Store = {
    facilityStore: new FacilityStore(),
    bookingStore: new BookingStore(),    
    surveyStore: new SurveyStore(),
    customerStore: new CustomerStore(),
    waitlistStore: new WaitlistStore(),
    appStore: new AppStore()
}

export const StoreContext = createContext(Store);

export function useStore() {
    return useContext(StoreContext);
}