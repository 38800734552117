import React from "react"
import { EuiText } from "@elastic/eui"

export default class RepairType {
  id: number = 0
  acronym: string = ""
  name: string = ""
  description: string = ""
  durationInWeeks: number = 0
  durationDescription: string = ""

  constructor(
    id: number,
    acronym: string,
    name: string,
    description: string,
    durationInWeeks: number,
    durationDesc: string
  ) {
    this.id = id
    this.acronym = acronym
    this.name = name
    this.description = description
    this.durationInWeeks = durationInWeeks
    this.durationDescription = durationDesc
  }

  getUIDetails = ():
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal => {
    return (
      <EuiText grow={false}>
        Your vehicle requires
        <br />
        <strong>{this.name}</strong>
        <br />
        <p className="highlightedText">{this.durationDescription}</p>
      </EuiText>
    )
  }

  getUIDescription = ():
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal => {
    return (
      <EuiText grow={false}>
        <h4>About this Repair Type</h4>
        <p>{this.description}</p>
      </EuiText>
    )
  }
}
