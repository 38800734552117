import { Customer, CustomerDetailsForm, Booking } from "../models"
import { makeAutoObservable } from "mobx"
import agent from "../api/agent"
import * as signalR from "@microsoft/signalr"
import { ProcessStates, RepairTypes } from "../entities/constants"
import { navigate } from "gatsby"

export default class CustomerStore {
  customer: Customer | undefined = undefined
  currentBooking: Booking | undefined = undefined
  encryptedQuoteNumber: string = ""
  formValues: CustomerDetailsForm = {
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    confirmEmail: "",
    vehicleRego: "",
    vehicleMake: "",
    vehicleModel: "",
  }
  isLoading = true
  showRepairTypeChangedAlert = false
  bookingHubConnection: signalR.HubConnection | null = null
  customerId: string = ""

  constructor() {
    makeAutoObservable(this)
  }

  loadCustomer = async () => {
    try {
      //display error if encrypted quote number isn't available
      if (!this.encryptedQuoteNumber) {
        navigate("/cannotproceed")
        return
      }

      const token = await agent.Identity.getToken(this.encryptedQuoteNumber)
      localStorage.setItem(
        process.env.GATSBY_LSK_HAILCX_API_TOKEN ?? "hailcx-api-token",
        token
      )

      const customer = await agent.Customers.details(this.encryptedQuoteNumber)
      this.setCustomer(customer)
      this.setFormValues(customer)
      this.setIsLoading(false)
    } catch (error) {
      console.error(error)

      this.setIsLoading(false)
    }
  }

  setCustomerEncryptedQuoteNumber = (encryptedQuoteNumber: string) => {
    this.encryptedQuoteNumber = encryptedQuoteNumber
  }

  setCustomer = (customer: Customer) => {
    if (customer)
    {
      customer.firstName = customer.firstName !== null ? customer.firstName : "";
      customer.lastName = customer.lastName !== null ? customer.lastName : "";
      customer.mobile = customer.mobile !== null ? customer.mobile : "";
      customer.email = customer.email !== null ? customer.email : "";
      customer.vehicleRego = customer.vehicleRego !== null ? customer.vehicleRego : "";
      customer.vehicleMake = customer.vehicleMake !== null ? customer.vehicleMake : "";
      customer.vehicleModel = customer.vehicleModel !== null ? customer.vehicleModel : "";
    }

    this.customer = customer;
        
    console.log("setCustomer", JSON.stringify(customer))
  }

  setCurrentBooking = (booking: Booking) => {
    this.currentBooking = booking
    console.log("setCurrentBooking", JSON.stringify(booking))
  }

  setCustomerId = (customerId: string) => {
    this.customerId = customerId
  }

  loadCurrentBooking = async () => {
    try {
      const booking = await agent.Customers.getCurrentBooking(this.customerId)
      this.setCurrentBooking(booking)
    } catch (error) {
      console.error(error)
    }
  }

  setFormValues = (customer: Customer) => {
    const {
      id,
      halQuoteNumber,
      registeredAtFacilityId,
      repairTypeQuoted,
      repairTypeName,
      repairTypeDurationInWeeks,
      currentStateOfBookingProcessFlow,
      activeBookingId,
      activeBookingManageUrl,
      ...customerData
    } = customer
    this.formValues = { ...customerData, confirmEmail: "" }
    console.log("setFormValues", JSON.stringify(this.formValues))
  }

  setIsLoading = (loading: boolean) => {
    this.isLoading = loading
  }

  updateCustomer = async (customerInfo: {}) => {
    if (this.customer) {
      try {
        await agent.Customers.update(this.customer.id, customerInfo)
        const updatedCustomer = await agent.Customers.getCustomer(
          this.customer.halQuoteNumber
        )

        this.setCustomer(updatedCustomer)
        console.log("updateCustomer", JSON.stringify(updatedCustomer))
        return true
      } catch (error) {
        console.error(error)

        return false
      }
    }
  }

  saveProcessState = async (stateId: number, processState: string) => {
    if (this.customer) {
      const customerProcessState =
        this.customer.currentStateOfBookingProcessFlow

      try {
        if (
          !customerProcessState ||
          (ProcessStates.find(state => state.Name === customerProcessState)
            ?.Id || 0) < stateId
        ) {
          await agent.Customers.setProcessState(
            this.customer.halQuoteNumber,
            processState
          )
          this.setCustomer({
            ...this.customer,
            currentStateOfBookingProcessFlow: processState,
          })
        }

        return true
      } catch (error) {
        console.error(error)

        return false
      }
    }
  }

  setShowRepairTypeChangedAlert = (value: boolean) => {
    this.showRepairTypeChangedAlert = value
  }

  addCustomerToWaitlist = async () => {
    if (this.customer) {
      try {
        await agent.Customers.addCustomerToWaitlist(this.customer.id)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    }
  }

  removeCustomerFromWaitlist = async () => {
    if (this.customer) {
      try {
        await agent.Customers.removeCustomerFromWaitlist(this.customer.id)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    }
  }

  isCustomerWaitlisted = async () => {
    if (this.customer) {
      try {
        return await agent.Customers.isCustomerWaitlisted(this.customer.id)
      } catch (error) {
        console.error(error)
        return false
      }
    }
  }

  createBookingHubConnection = () => {
    const endpointUrl =
      process.env.GATSBY_API_BASE_URL +
      "/booking-hub?quoteNumber=" +
      this.customer?.halQuoteNumber

    const connection = new signalR.HubConnectionBuilder()
      .withUrl(endpointUrl)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build()

    connection
      .start()
      .then(() => {
        console.log("SignalR connection started.")
      })
      .catch(function (err) {
        return console.error(err.toString())
      })

    connection.on("SetRepairType", req => {
      const repairType = RepairTypes.find(rt => rt.id === req.repairType)

      if (repairType && this.customer) {
        if (this.customer.repairTypeQuoted) {
          // Repair type has been updated pop up
          this.setCustomer({
            ...this.customer,
            repairTypeQuoted: repairType.acronym,
            repairTypeName: repairType.name,
            repairTypeDurationInWeeks: repairType.durationInWeeks,
          })
          this.setShowRepairTypeChangedAlert(true)
        } else {
          //Set repair type for the first time
          this.setCustomer({
            ...this.customer,
            repairTypeQuoted: repairType.acronym,
            repairTypeName: repairType.name,
            repairTypeDurationInWeeks: repairType.durationInWeeks,
          })
          navigate("/book/ready-to-book")
        }
      }
    })
  }

  getVehicleInfo = () => {
    //debugger
    if (this.customer) {
      const vehicleInfo = `${this.customer.vehicleRego} ${this.customer.vehicleMake} ${this.customer.vehicleModel}`
      //const vehicleInfo = "XAF083 Ford Focus"
      return vehicleInfo
    } else {
      return undefined
    }
  }
}
